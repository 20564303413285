@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");

.cartncheckout {
  display: flex;
  justify-content: space-between;
  margin: 2px;
  background-color: transparent;
  border-radius: 13px;
  padding: 20px;
}

.cart-column {
  flex: 1;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  background-color: white;
}

.checkout-column {
  flex: 1;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  background-color: white;
}

.card-container {
  margin: 0;
  width: 100%;
  padding: 0;
 
}
.emptycart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.continueShopping{
  width: 200px;
  padding: 10px;
  text-decoration: none;
  font-weight: 600;
  align-items: end;
  border-radius: 20px;
  margin: 40px;
  background-color: #f0f0f0;
}

.continueShopping:hover{
  background: #F6F9FE;
  color: #174ea6;
  background-color: coral;
  transition: 0.5s;
}
.continueShopping a{
  text-decoration: none;
  font-size: 17px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: auto;
  background-color: white;
  
  border-radius: 13px;
  transition: 0.6s;
  cursor: pointer;
}

.cart-item:hover {
  transform: scale(1.1);
}

.product-image {
  width: 80px;
  margin-right: 10px;
}

.product-image img {
  width: 100%;
  height: auto;
}

.visible-paragraph {
  font-size: 20px;
  color: gold;
  font-weight: bold;
}

.hidden-paragraph {
  display: none;
}

.cart-item:hover .hidden-paragraph {
  display: block;
  font-size: 13px;
}

.product-details {
  flex: 1;
}

.quantity {
  display: flex;
  align-items: center;
}

.quantity button {
  padding: 5px;
  font-size: 14px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.quantity span {
  margin: 0 10px;
}

.product-total {
  text-align: right;
}

.product-total p {
  margin: 0;
}

.payoutDiv{
  background-color: #fff;
  border-radius: 14px;
  padding: 20px;
  margin: 40px;
  box-shadow: 0 2px 4px rgb(124, 124, 124);
}

.subtotal,
.tax,
.total {
  margin-top: 10px;
  text-align: right;
  color: black;
  font-size: 18px;
}


.subtotal span,
.tax span,
.total span {
  font-weight: bold;
}

.checkout-column h2 {
  margin-bottom: 10px;
}

.form-field{
  padding: 8px;
  border: none;
}


.payment-methods {
  margin-bottom: 10px;
}
.note p{
  font-size: 14px;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-style: italic;
  color: #ff0800;
}



.checkout-column a {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #007bff;
  text-decoration: none;
}

.checkout-column a:hover {
  text-decoration: underline;
}

.checkoutForm{
  display: flex;
  flex-direction: column;
  border-radius: 0;
  box-shadow: none;
}



.selection{
  width: 25rem;
}



.checkoutForm .error-message {
  color: red;
  margin-top: 5px;
}

.checkout-column form{
  padding: 30px 0 0 0;
}
.checkout-column form .form-row{
  display: flex;
  margin: 32px 0;
}

form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}  

form .form-row .textarea{
  height: 70px;
}
.input-data input,
.textarea textarea{
  display: block;
  
  height: 100%;
  border: none;
  font-size: 17px;
  margin: 10px;
  padding: 8px;
  width: 25rem;

}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
  border: none;

}
.textarea textarea{
  resize: none;
  padding-top: 10px;
  border: none;

}
.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 30px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before{
  transform: scale(1);
}
.paymentSelection{
  width: 250px;
  height: 50px;
  border-radius: 10px;
  border-color:antiquewhite;
}
.paymentChoice{
  min-width: 200px;
  padding: 20px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 height: auto;
 border-radius: 50px;
 background: #e0e0e0;
 box-shadow: 20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;
}
.paymentChoice p{
  
  font-size: 13px;
  font-family: Helvetica;
  color: #0e0101;
  text-align: left;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 2px;
  text-indent: 20px;
  margin: 10px 0;
  padding: 0;
  background-color: #f0f0f0;
  border-radius: 17px;
  padding: 7px;

}
.paidButton{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
  background-color: coral;
  transition: 0.5s;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

@media screen and (max-width: 720px) {
  .cartncheckout {
    margin: 2px;
    flex-direction: column;
    padding: 0px;
  }

  .cart-column,
  .checkout-column {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
  }
  .checkout-column {
    margin-left: 20px;
    margin-right: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .cart-item:hover {
    transform: scale(1.001);
  }
  .checkout-column form .form-row{
    display: flex;
    flex-direction: column;
    margin: 12px 0;
  }
  .checkoutForm{
    background-color: white;
    width: 100%;
    border-radius: 0;
    box-shadow: none;

  }

  .visible-paragraph {
    font-size: 13px;
  }
  .input-data input,
.textarea textarea{
  width: 80%;
  display: flex;
  flex-direction: column;
}


.container form .form-row{
  display: column;
}

form .form-row .input-data{
  margin: 15px 0!important;
}
.form-field{
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  padding: 20px;
}

.selection{
  width: 20rem;
  height: 20px;
}

}
