.flashSale{
  margin: 40px; 
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
}
.card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin: 10px;
    background-size: cover;
    height: 160px;
    padding: 17px;
    border-radius: 30px;
    height: auto;
    border-radius:14px;

}
.flash-sale-icon{
  color: rgb(151, 151, 23);
  transform: rotate(30deg);
}
.flashSale h2{
    font-size: 50px;
    margin-bottom:10px;
    
  }
  .card {
    width: 300px;
    border-radius: 5px;
    padding: 10px;
    margin:10px;
    border-radius: 14px;

    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.37);
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    height: 400px;
    
  }
  .card:hover{
    transition: 0.6s;
    transform: scale(1.3);
  }
  
  .card img {
    max-width: 80%;
    margin-bottom: -10px;
    margin-top:   10px;
  }
  
  
  .card h3 {
    font-size: 18px;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .card p{
    font-size: 14px;
    color: rgb(29, 0, 158);
    font-weight: 500;
  }


  .visible-paragraph{
    font-size: 14px;
    color: rgb(67, 233, 67);
    font-weight: 900;
  }
  
  .card:hover .visible-paragraph {
    display: none;
  }
  .hidden-paragraph{
    display: none;
  }
  
  
  .card:hover .hidden-paragraph {
    display: block;
    
  }
  
  
  .arrow-icon {
    display: none;
  }
  .card:hover .arrow-icon{
    display: inline;
    color: rgb(48, 146, 10);
    transition: .5s;
    font-weight: 900;
    background-color: transparent;
    font-size: 30px;
    margin-right: 5px;
    color: blue;
    margin-left: 90%;
  }
  @media screen and (max-width:480px){
    .flashSale{
      margin: 0; 
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      padding: 20px;
    }

    .card-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .card {
        width: 100%;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .card:hover{
      transition: 0.6s;
      transform: scale(1.1);
    }

    .flashSale h2{
      font-size: 40px;
      margin-bottom:-10px;
      margin-top: 10px;
      
    }
    

    .visible-paragraph{
        font-size: 14px;
        color: rgb(4, 39, 7);
        font-weight: bold;
      }
    .arrow-icon{
        display: inline;
        color: rgb(48, 146, 10);
        transition: .5s;
        font-weight: 900;
        background-color: transparent;
        font-size: 25px;
        margin-top: 2px;
        margin-right: 5px;
        color: blue;
        margin-left: 90%;
      }
  }