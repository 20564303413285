@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.frequentAQ{
 
  
  
  font-family: 'Open Sans', sans-serif;
  max-width: 600px;
}

.container h1{
  
  text-align: center;
  place-items: center;
}

details{
    display: flex;
    grid-template-columns: repeat(2 2fr);
    background-color: #f1f3ed;
    font-size: 1rem;
    padding:10px;
    border-radius: 10px;
    margin: 20px;
    font-weight: lighter;
}

summary {
  padding: .5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;  
  transition: height 1s ease-;
  border-radius: 10px;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after{
  content: "\002B";
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: .5em;
}

details[open] summary:after{
  content: "\00D7";
}

details[open] div{
  padding: .5em 1em;
}

@media  screen and (max-width:480px){
    .container h1{
        font-size: 20px;
    }
    details{
        font-size: 17px;
        margin: 16px;
        font-weight: bold;
    }
}