.convenience-container {
    padding: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);

  }
  
  .convenience-column {
    flex-basis: calc(100% / 3 - 20px);
    margin: 10px 10px;
    text-align: center;
    width: 500px;
  }
  .convenience-column:hover{
    transition: 0.7s;
    
    border-radius: 13px;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    
  }
  .convenience-column:hover p{
    color:  rgb(10, 2, 31);
  }
  .convenience-column:hover h3{
    color:  rgb(10, 2, 31);
  }
  .convenience-column:hover .convenience-icon{
    background-color: transparent;
  }


  
  .convenience-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    background-color: transparent;
    
    border-radius: 50%;
    font-size: 24px;
  }
  
  .convenience-icon svg {
    color: blueviolet;
    
  }
  
  .convenience-container h3 {
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 500;
    color: black;
    font-weight: bold;


     


  }
  
  .convenience-container p {
    margin: 0;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    color: black;
    
  }
  @media screen and (max-width: 720px) {
    
    .convenience-container {
        justify-content: center;
        margin: 0;
        border-radius: 0;
        box-shadow: none;

    }
    
    .convenience-column {
        flex-basis: calc(100% / 3 - 20px);
        margin: 10px 10px;
        text-align: center;
        border-radius: 13px;
        margin: 0;
        padding: 10px;
        background: white;
        box-shadow: 0 2px 2px 0 rgb(188, 187, 253);
        backdrop-filter: blur( 2px );
        -webkit-backdrop-filter: blur( 2px );

        margin-top: 5px;
        
        min-width:  380px;
        
        
      }
      .convenience-column:hover{
        transition: none;
        
        transform: none;
       
      }
      .convenience-icon {
        margin-top: -10px;
        margin-bottom: -10px;
      }
      .convenience-container p {
        font-size: 13px;
      }

  }
  