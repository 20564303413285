

  .products {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: center;
    padding: 20px;
    top: 80px;
  }
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 10px;
    background-color: rgb(247, 228, 228);
    width: 280px;
    
  }
  
  .cart-icon {
    position: fixed;
    top: 70px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    backdrop-filter: blur(15px);
    background-color: white;

  }
  
  .cart-count {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
    height: auto;
    
  }
  
  .product-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .product-card img {
    max-width: 80%;
    margin-top: -60px;
    margin-bottom: 10px;
  }
  
  .price {
    display: flex;
    align-items: center;
    margin-top: auto;
  }
  
  .price span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    color: goldenrod;
  }
  
  .price button {
    background-color: #041d36;
    color: white;
    font-size: 13px;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 125px;
    margin-left: 4px;
    margin-right: 8px;
    
  bottom: 0;
  right: 0;
  }
  
  .price button:hover {
    background-color: #0061b8;
  }
  .inner {
		padding: 20px;
		padding-top: 40px;
		background-color: transparent;
		border-radius: 12px;
	 position: relative;
		overflow: hidden;
    width: 100px;
    margin-left: 70%;
    
    
    
	}
	
	.pricing {
		position: absolute;
		top: 0;
		right: 0;
		
    background-color: rgba(255, 255, 255, 0.45);
		border-radius: 14px;
		display: relative;
		align-items: center;
		padding: .625em .75em;
		font-size: 13px;
		font-weight: bolder;
    font-style: italic;
		color: goldenrod;
	}

  .no-products {
    text-align: center;
    margin-top: 20px;
  }
  
  .no-products p {
    font-size: 18px;
    color: #555;
  }
  
  .inquire-button {
    background-color: #041d36;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 125px;
    margin-left: 4px;
    margin-right: 8px;
  }
  
  .inquire-button:hover {
    background-color: #e0e0e0;
    color:  #041d36;
  }
  


  @media screen and (max-width: 1200px){
    .cart-icon {
      right: 1.5rem;
    }
    .product-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 20px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 800px){
    .cart-icon {
      right: 1.5rem;
    }
    .product-list {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 30px;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 720px){
    .cart-icon {
      right: 1.5rem;
    }
    .product-list {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 10px;
      margin-top: 20px;
    }

    .price button {
      margin-left: 40px;
      margin-right: 10px;
      font-size: 11px;
    }


    .product-card {
      width: 320px;
      background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  
    }
  }