*, *:after {
    box-sizing: border-box;
  }
  
  .procedure {
    display: grid;
    place-items: center;
    min-height: 10vh;
    padding: 10px;
    text-align: center;
    margin: 10px;
   
  }
  
  .procedure h1 {
    font-size: clamp(28px, 6vmin, 78px);

    font-family: sans-serif;
    color: hsl(0, 83%, 21%);
  }
  
  .underlined {
    position: relative;
  }
  
  .underline-mask::after {
    content: '';
    position: absolute;
    top: 95%;
    width: 150%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 6px solid hsl(280 80% 50%);
    /* Use a conic gradient mask to hide and show the bits you want */
    --spread: 140deg;
    --start: 290deg;
    mask: conic-gradient(from var(--start), white 0 var(--spread), transparent var(--spread));
  }
  
  .underline-overflow {
    display: inline-block;
    /* A little extra padding and overflow hidden   */
    overflow: hidden;
    padding-bottom: clamp(1rem, 2vmin, 2rem);
  }
  
  .underline-overflow::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 150%;
    aspect-ratio: 2.5 / 1;
    left: 50%;
    transform: translate(-50%, -10%);
    border-radius: 50%;
    border: 6px solid hsl(10 80% 50%);
  }
  
  .underline-clip::after {
    content: '';
    position: absolute;
    top: 95%;
    width: 150%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 6px solid hsl(130 80% 50%);
    /* Use a clip-path to hide and show the bits you want */
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }
  
  /* Mobile responsive styles */
  @media screen and (max-width: 767px) {
    .procedure h1 {
      font-size: clamp(20px, 3vmin, 25px);

    }
    
    .procedure {
      padding: 5px;
      max-height: 150px;
      margin-bottom: -200px;
    }
    
    .underline-mask::after,
    .underline-overflow::after,
    .underline-clip::after {
      border-width: 4px;
    }
  }
 