
form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
  }
  
  
  input[type="text"] {
    padding: 10px;
    border: 1px solid #a968fd;
    border-radius: 14px;
    margin-right: 10px;
    font-size: 16px;
    width: 300px;
  }
  
  button[type="submit"] {
    padding: 10px;
    background-color: #0b0420;
    color: #fff;
    border: none;
    border-radius: 14px;
    font-size: 15px;
    cursor: pointer;
    margin-left: 3px;
  }
  
  button[type="submit"]:hover {
    background-color: #f00;
  }
 
    @media screen and (max-width: 720px) {
        
      form {
        margin-top: 40px;
        margin-left: -20px;
        padding: 20px;
        position: relative;
        width: auto;
        background-color: transparent;
        
      }

      input[type="text"] {
        
        
        font-size: 13px;
        width: 200px;
      }


        button[type="submit"] {
            padding: 10px;
            
            color: #fff;
            border: none;
            
            font-size: 13px;
            cursor: pointer;
          }
      }
  