


.about-us {
  display: flex;
  margin: 40px;
  justify-content: space-between;
  padding: 20px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  font: bold 120px Poppins, sans-serif;
  
}
.about-us:hover{
  transform: scale(1);
}

.text-column {
  flex: 1;
  margin-right: 40px;
  font-size: 23px;
  font-weight: 400;
  padding:40px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  
}
.text-column:hover{
  transition: 0.6s;
  transform: scale(1.05);
}
.bodyText{
  background-image: linear-gradient(60deg, #000000, #080f1b);
  background-clip: text;
  color: transparent;

}


.iphone-column {
  width: 500px; /* Adjust the width as per your requirement */
 

}

.iphone-x {
  width: 360px;
  height: 650px;
  border-radius: 17px;
  margin: 40px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  
  border: 1px solid rgba( 255, 255, 255, 0.18 );


  background-image: url('img/screenshot.png');
   background-repeat: no-repeat;
   background-size: cover;
   padding: 5px;
}
.iphone-x:hover{
  transition: 0.6s;
  transform: scale(1.2);
}
.deliveryGuy{
  height: 20rem;
  border-radius:20px;
  margin-left: -70px;
  transform: rotate(-20deg);

}
.shoponTheGo{
  height: 15rem;
  border-radius:20px;
  position: absolute;
  bottom: 40px;
  right: 0;
  transform: rotate(20deg);
  margin-right: -50px;
}

.icon-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



@media screen and (max-width: 768px) {
  .about-us {
    flex-direction: column;
    margin: 10px;
    padding: 3px;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    background-image:none;
    font: bold 120px Poppins, sans-serif;



  }

  .text-column {
    margin-left:10px;
    margin-right: 40px;
    padding: 15px;
    width: 360px;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 600;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);

  }
  .text-column:hover {
    transition: none;
    font-weight: normal;
    transform: none;
  }

  

  .iphone-column {
    width: 100%;
    margin-left: 40px;

  }
  .iphone-x{
    width: 270px;
    height: 550px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .iphone-x:hover{
    transition: none;
    transform: none;
  }
  .shoponTheGo{
    height: 10rem;
    border-radius:20px;
    position: absolute;
    bottom: 100px;
    right: 0;
    transform: rotate(20deg);
    margin-right: -30px;
  }
  .icon-column {
    margin-top: 20px;
  }
}

