.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 20px;
  
  
}


.navbar__logo {
  display: flex;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
 
  color: #000;
  
}

.navbar__links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar__links li {
  margin:0 20px;
  height: 30px;
  
}

.navbar__links a {
  font-size: 17px;
  color:rgb(57, 58, 22);
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 7px 13px;
}
.navbar__links a:active{
  background-color: #3ba731;
}


.navbar__links a:hover {
  color: #010318;
  background: #049730;
  transition: .5s;
}

.time {
  font-size: 16px;
  color: #333;
  margin: 0;
  padding: 0;
}



.menuBtn{
  display: none;
 }


@media screen and (max-width:720px){
  
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar__links {
    display: none;
  }

  .navbar__links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #f1f1f1;
    padding: 1rem;
  }

  .navbar__links.open li {
    margin: 0.5rem 0;
  }

  .menuBtn{
    
     font-size: 35px;
     color: #041d36;
     background-color: transparent;
     line-height: 20px;
     cursor: pointer;
     transition: 0.5s;
     position: fixed;
     right: 10px;
     margin-right: 40px;

   
   }
   .menuBtn:hover{
    color: #744ac4;
    background-color: transparent;
  }
}