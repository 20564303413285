.Checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

form {
  width: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

}

form > div {
  margin-bottom: 10px;

}
.Details{
  display: flex;
  margin-left: 20px;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  margin: 1em 0 1em 0;
  width: 300px;
  position: relative;
  outline: 0;
  border: 1px solid rgb(219, 213, 213);
  padding: 8px 14px;
  border-radius: 8px;
  height: 43px;
}
.Location{
  margin: 20px;
  padding: 20px;
  border-radius: 13px;
  display: block;
  background-color: white;
  box-shadow: 0 1px 1px rgb(40, 25, 49);
  width: 75%;
  justify-content: center;
}

button[type="submit"] {
  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all .3s;
  box-shadow: 6px 6px 12px #c5c5c5,
              -6px -6px 12px #ffffff;
}
button:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5,
              inset -4px -4px 12px #ffffff;
 }
button[type="submit"]:hover {
  background-color: #0056b3;
}


@media screen and (max-width: 720px) {
  .Checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  form{
    width: 800px;
    align-items: flex-start;
  }
  form > div {
    margin-bottom: 5px;
  }

  .Details {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 300px;
  }

  .Location{
    margin: 10px;
    padding: 10px;
    display: block;
    width: 300px;
    justify-content: center;
  }
  .checkoutForm{
    background-color: black;
  }
  .selection{
    width: 80%;
  }
  
  
}