.backgroundImg{
     background-image: url('img/backImg.jpg');
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     height: 100%;
}
.banner {
     padding: 40px;
     text-align: center;
     background: rgba( 255, 255, 255, 0.25 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
     
  }
  
  .banner h1 {
    display: grid;
    place-content: center;
    background-color: var(--background-color);
    min-height: 10vh;
    font-family: "Oswald", sans-serif;
    font-size: clamp(16px, 100px, 28px);    font-weight: 700;
    text-transform: uppercase;
    color: var(--text-color);
    margin: 0;
  }
  .banner h1 > div {
    grid-area: 1/1/-1/-1;
  }


  .banner p {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
 
   

  .cta-button {
    text-decoration: none;
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.cta-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.cta-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fcfcfc;
  left: 0;
  top: 0;
  border-radius: 10px;
}



 
  .cta-button a {
    text-decoration: none;
    font-size: 20px;
  }
  .northeast-arrow{
    margin-left: 10px;
    font-weight: lighter;
    font-size: 25px;
    transform: rotate(45deg);
  }
  

  :root {
    
    --text-color: hsl(0, 100%, 1%);
  }
  
  .wrapper {
    display: grid;
    place-content: center;
    
    min-height: 30vh;
    font-family: "Oswald", sans-serif;
    font-size: clamp(20px, 100px, 112px);
    font-weight: 300;
    text-transform: uppercase;
    color: var(--text-color);
  }
  
  .wrapper > div {
    grid-area: 1/1/-1/-1;
  }
  .top {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
  }
  .bottom {
    clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
    color: transparent;
    background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background: linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
  }
  @media screen and (max-width:480px){
    .backgroundImg{
      background-image: url('img/mobileBanner.jpeg');
      
 }

    .banner h1 {
        font-size: 20px;
        margin: 0;
      }

      .cta-button a{
        margin-left:-20px;
        margin-right: -20px;
        font-size: 20px;
        font-weight: bold;
      }

      .wrapper {
        min-height: 5vh;
      }
  }