footer {
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
}

div {
  flex-basis: 25%;
  margin-bottom: 20px;
}

p {
  font-weight: bold;
  margin-bottom: 10px;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 480px){
  footer {
    display: flex;
    flex-direction: column;
  }
  
}