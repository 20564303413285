.reviews{
    margin: 10px;
    
    display: flex;
    
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: first baseline;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    
}
.review {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
    padding: 10px;
    
    border-radius: 14px;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    width: 400px;
    height: 130px;

  }
  
  .review h3 {
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 6px;
  }
  
  .review p {
    font-size: 14px;
    margin-bottom: 2px;
    align-items: flex-start;
    color: rgb(119, 5, 5);
    margin-top: 2px;
  }
  
  .rating {
    font-size: 20px;
    color: #ffd700; /* Star color (e.g., gold) */
  }
  @media screen and (max-width:480px){
    .reviews{
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
  }
  .review {
    width: 347px;
    height: 130px;
    font-size: 13px;
  }
  }