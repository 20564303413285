.cart-page {
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 20px 10px 78px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  background-color: #fff;
  

  
}

table {
  width: 90%;
  border-collapse: collapse;
  margin: 1rem 2rem;
  border-radius: 14px;
  
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 6px solid #ffffff;
  
  justify-content: space-between;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  
}

button {
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: #0077c2;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin: 20px;
}

button:hover {
  cursor: pointer;
  background-color: #005ea3;
}

.subtotal, .tax, .total {
  display: flex;
  justify-content: space-between;
  margin: 2rem 2rem;
  color: green;
}


.subtotal span, .tax span, .total span {
  font-weight: bold;
}

button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}
h2{
    text-align: center;
}

@media (max-width: 768px) {
    .cart-page {
      max-width: 100%;
      padding: 1rem;
      margin: 1rem 1rem;
    }
    
    table {
      font-size: 13px;
      
      width: 100%;
      margin-left: -10px;
    }
    
    th, td {
      padding: 0.5rem;
    }
    
    button {
      font-size: 1rem;
      padding: 0.3rem;
      margin: 10px;
    }
    
    .subtotal, .tax, .total {
      
      flex-direction: row;
    }
  }
  
