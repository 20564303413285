.contactUs {
    text-align: center;
    max-width: 400px;
    margin-left: 35%;
    margin-right: 30%;
  }
  
  .contactLinks {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    margin-top: 20px;
    
  }
  .linkSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: 100px;
    width: 100px;
    background: rgba(255, 250, 250, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .linkSection:hover{
    transition: 0.6s;
    transform: scale(1.2);
    background: rgb(246,190,62);
    background: linear-gradient(29deg, rgba(246,190,62,1) 14%, rgba(233,228,214,1) 50%, rgba(238,231,125,1) 79%);

  }
  
  .contactLinks a {
    display: inline-block;
    margin: 0 10px;
    color: #333;
    text-decoration: none;
  }
  
  .contactLinks a i {
    margin-right: 5px;
  }
  
  .contactLinks a:hover {
    color: #555;
  }
  .icons{
    height: 40px;
    border-radius: 30px;
  }
  @media screen and (max-width:720px){
    .contactUs {
        text-align: center;
        max-width: 400px;
        margin: 0;
      }
      
    
  }